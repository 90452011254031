import Risk from './risk';

export default class Risks {
  risks: Risk[];

  constructor() {
    this.risks = [];
  }

  forEach(callback: (item: Risk) => void) {
    for (const item of this.risks) {
      callback(item);
    }
  }
}
