import React, { ReactNode } from 'react';
import OutlookService from 'services/Office/outlookService';
import AppContext, { IAppContext } from './AppContext';
import CurrentUser from 'models/currentuser';
import AppError from 'utils/appError';
import { apiGetTaskForEvent } from 'services/Api/taskService';
import { convertItemId } from 'utils/outlook';
import Logger from 'services/Logging/logService';
import { isValidDate } from 'utils/datetime';
import { toast } from 'react-toastify';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { globalDesktopHeight, globalDesktopSize } from 'globalConstants';
import { apiRequest } from 'services/Auth/authConfig';
import { Client } from '@microsoft/microsoft-graph-client';
import { DefLanguageCode } from 'models/setting';
import { LocalStorageKeys, getLocalStorageData, setLocalStorageData } from 'utils/localstorage';
import { IAppProps } from './App';

//
// Types
//
export type AuthStateUpdate = (
  isAuthenticated?: boolean | undefined,
  user?: CurrentUser | undefined,
  isAuthInProgress?: boolean | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any | undefined,
) => void;

export type SetGlobalDataCache = (globalDataCache: GlobalDataCache) => void;

export type IGraphInterface = {
  accessToken: string;
  client: Client;
};

//
// Global vars
//
export let globalOrgUnitId: string | undefined = undefined;
export let globalUserLang: string | undefined = undefined;
export let globalDefOrgLang: string | undefined = undefined;

interface IAppContextProps extends IAppProps {
  children?: ReactNode;
}

class AppContextProvider extends React.Component<IAppContextProps, IAppContext> {
  constructor(props: IAppContextProps) {
    super(props);

    this.state = {
      useDarkMode: false,
      setUseDarkMode: this.setUseDarkMode,
      isAppLoading: false,
      isContentLoading: false,
      showContentLoader: this.showContentLoader,
      hideContentLoader: this.hideContentLoader,
      error: undefined,
      showNotification: this.showNotification,
      setError: this.setErrorMessage,
      getAccessToken: this.getAccessToken,
      hasScopes: this.hasScopes,
      isAuthenticated: false,
      isAuthInProgress: true, // set to true because we start with a silent SSO request
      user: CurrentUser.getEmptyUser(),
      task: undefined,
      item: undefined,
      itemId: undefined,
      itemStart: undefined,
      isGettingItemId: false,
      isGettingItemStart: false,
      isNewAppointment: false,
      isReady: this.isReady,
      globalDataCache: new GlobalDataCache(),
      firstRun: false,
      cacheMiss: this.cacheMiss,
      getGraphInterface: this.getGraphInterface,
      windowSize: globalDesktopSize,
      isMobileView: false,
      windowHeight: globalDesktopHeight,
      hasResourcePanel: false,
      showResourcePanel: false,
      isMainNavCollapsed: false,
      startFirstRun: this.startFirstRun,
      login: this.login,
      logout: this.logout,
      globalFilter: [],
      setGlobalFilter: () => {},
    };
  }

  //
  // Startup
  //
  componentDidMount() {
    this.Initialize();
  }

  isOutlookDarkMode() {
    //switch to dark mode when the background color of Outlook is 'dark', meaning that all 3 RGB components are below average
    try {
      Logger.debug('office theme', Office.context.officeTheme);
      if (Office.context.officeTheme) {
        const rgb = Office.context.officeTheme.bodyBackgroundColor;
        Logger.debug('office background color', rgb);
        if (!rgb || rgb.length < 6) return false;

        const R: string = rgb.substring(0, 2);
        const G: string = rgb.substring(2, 4);
        const B: string = rgb.substring(4, 6);

        if (R < '80' && G < '80' && B < '80') {
          Logger.debug('switching to dark mode');

          return true;
        } else {
          return false;
        }
      } else {
        return getLocalStorageData(this.state, LocalStorageKeys.DarkModeOutlook) === 'true';
      }
    } catch (err) {
      return false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidUpdate(prevProps: any, prevState: any) {
    // get the rest item Id when Outlook signals that a new item is opened. this is async
    if (prevProps.item !== this.props.item) {
      this.getAppointmentItemRestId();
      this.getAppointmentItemStart();
    }

    // load the task data when
    // a. after user authentication and current item Id is set and the itemStart is set
    // b. when user is authenticated and current item Id is changed
    if (prevState.isAuthenticated !== this.state.isAuthenticated && this.state.itemId && this.state.itemStart) {
      this.loadTaskForAppointment();
    }

    if (this.state.isAuthenticated && prevState.itemId !== this.state.itemId) {
      this.loadTaskForAppointment();
    }
  }

  Initialize = async () => {
    try {
      // Set the light or dark mode
      var useDarkMode = this.isOutlookDarkMode();
      this.setState({
        useDarkMode: useDarkMode,
      });

      // Initial resize
      this.onResize();

      switch (this.props.item.itemType) {
        case Office.MailboxEnums.ItemType.Appointment:
          this.getAppointmentItemRestId();
          this.getAppointmentItemStart();
          break;
        case Office.MailboxEnums.ItemType.Message:
        //not supported
      }

      await OutlookService.ssoSilent(this.authStateUpdate, this.setGlobalDataCache);
    } catch (err) {
      Logger.debug('Error while initializing', err);
      this.setErrorMessage(err);
    }
  };

  //
  // Loader functions
  //
  showContentLoader = () => this.setState({ isContentLoading: true });

  hideContentLoader = () => this.setState({ isContentLoading: false });

  isReady = (): boolean => {
    const ready: boolean =
      !this.state.isAuthInProgress &&
      !this.state.isGettingItemId &&
      !this.state.isAppLoading &&
      !this.state.isGettingItemStart;

    Logger.debug('ready', ready);

    return ready;
  };

  //
  // Light and dark mode functions
  //
  setUseDarkMode = (useDarkMode: boolean) => {
    setLocalStorageData(this.state, LocalStorageKeys.DarkModeOutlook, useDarkMode.toString());
    this.setState({ useDarkMode: useDarkMode });
  };

  //
  // Authentication service wrapper functions
  //
  authStateUpdate = (
    isAuthenticated?: boolean | undefined,
    user?: CurrentUser | undefined,
    isAuthInProgress?: boolean | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any | undefined,
  ) => {
    // Update the state with 1 statement, otherwise timing issues will arrise
    // So for each state property to set, check if it's defined and if so, set it, otherwise set it to the current state
    const newState = {
      isAuthenticated: isAuthenticated !== undefined ? isAuthenticated : this.state.isAuthenticated,
      isAuthInProgress: isAuthInProgress !== undefined ? isAuthInProgress : this.state.isAuthInProgress,
      user: user !== undefined ? user : this.state.user,
      error: error !== undefined ? error : this.state.error,
    };

    if (user && this.state.user.id !== user.id) {
      // When user is changed, let the global data cache know so it can change the language
      this.state.globalDataCache.setCurrentUserLanguage(user);

      // set language headers
      this.setLanguages(user);
    }

    this.setState(newState);
  };

  logout = () => {
    OutlookService.logout(this.authStateUpdate);
  };

  login = () => {
    OutlookService.login(this.authStateUpdate, this.setGlobalDataCache);
  };

  startFirstRun = () => {
    this.setState({ firstRun: true });
  };

  getAccessToken = async (scopes: string[]): Promise<string> => {
    let token: string = '';
    if (this.isApiScope(scopes)) {
      token = await OutlookService.getAccessToken();
    } else {
      token = await OutlookService.getGraphToken();
    }

    return token;
  };

  hasScopes = async (scopes: string[]) => {
    return await OutlookService.hasScopes(scopes);
  };

  getGraphInterface = async (scopes: string[], tenantId?: string): Promise<IGraphInterface> => {
    //Get a graph client interface
    //Scopes are fixed for the Outlook add-in in the manifest
    //When a tenantId is supplied, Outlook cannot obtain a graph client for another tenant
    const accessToken = await OutlookService.getGraphToken();
    const client = OutlookService.getGraphClient(accessToken);
    const graph: IGraphInterface = { accessToken, client };

    return graph;
  };

  isApiScope = (scopes: string[]): boolean => {
    return scopes[0] === apiRequest.scopes[0];
  };

  setOrgUnit = (orgUnitId: string | undefined) => {
    if (orgUnitId) {
      globalOrgUnitId = orgUnitId;
    } else {
      globalOrgUnitId = undefined;
    }
  };

  //
  // Global error message functions
  //
  showNotification = (msg: string, isError: boolean = false) => {
    if (isError) {
      toast.error(msg);
    } else {
      toast(msg);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setErrorMessage = (error: string | any) => {
    this.setState({
      error: this.normalizeError(error),
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  normalizeError = (error: string | any): AppError | undefined => {
    if (!error) {
      return undefined;
    }
    var normalizedError: AppError;
    if (typeof error === 'string') {
      var errParts = error.split('|');
      normalizedError = errParts.length > 1 ? new AppError(errParts[1], '', errParts[0]) : new AppError(error);
    } else {
      normalizedError = new AppError(
        error.message,
        error.code ? error.code : '',
        JSON.stringify(error),
        error.stack ? error.stack : '',
      );
    }

    return normalizedError;
  };

  //
  // Global data cache function
  //
  cacheMiss = async (count: number) => {
    //disable this because it can cause infinte render loops
  };

  setGlobalDataCache = (globalDataCache: GlobalDataCache) => {
    Logger.debug('setGlobalDataCache');
    globalDataCache.setAppContext(this.state);
    this.setState({ globalDataCache: globalDataCache.clone() });
  };

  //
  // Helpers
  //
  setLanguages = (user: CurrentUser) => {
    globalUserLang = user.language.code;
    globalDefOrgLang = this.state.globalDataCache.settings.get(DefLanguageCode) as string;
  };

  //
  // Main data loaders
  //

  getAppointmentItemRestId = () => {
    try {
      this.setState({ isGettingItemId: true });
      if (this.props.item) {
        const appointment = this.props.item;
        //if there is a seriesId, take that because we store that in the database
        let id: string | undefined = undefined;
        if (appointment.seriesId) {
          id = appointment.seriesId;
        } else if (appointment.itemId) {
          id = appointment.itemId;
        }
        if (!id) {
          //the Id is not populated, retrieve the itemId async
          Logger.debug('getting itemId async');
          appointment.getItemIdAsync((result: Office.AsyncResult<string>) => {
            if (result.status !== Office.AsyncResultStatus.Succeeded) {
              console.error(result.error.message);
              this.setState({ itemId: '', isGettingItemId: false });
              if (result.error.code === 9046) {
                //The id can't be retrieved until the item is saved.
                //this can be the case when the user opens the Add-in on a new appointment
                //new appointments do not have an Id yet
                //open de 'choose task' view
                Logger.debug('No itemId found, creating new event');
                this.setState({ isNewAppointment: true });
              } else {
                //show the error
                this.setErrorMessage(result.error.message);
              }
            } else {
              const itemId = convertItemId(result.value);
              Logger.debug('itemId', itemId);
              this.setState({ itemId: itemId, isGettingItemId: false });
            }
          });
        } else {
          const itemId = convertItemId(id);
          Logger.debug('itemId', itemId);
          this.setState({ itemId: itemId, isGettingItemId: false });
        }
      } else {
        Logger.debug('item is undefined, setting itemId to empty');
        this.setState({ itemId: '', isGettingItemId: false });
      }
    } catch (err) {
      Logger.debug('error: get itemId', err);
      this.setErrorMessage(err);
    } finally {
      this.setState({ isGettingItemId: false });
    }
  };

  getAppointmentItemStart = () => {
    try {
      this.setState({ isGettingItemStart: true, item: this.props.item });

      if (this.props.item) {
        const appointment = this.props.item;
        Logger.debug('current start', appointment.start);
        if (isValidDate(appointment.start)) {
          const startDate = new Date(appointment.start);
          Logger.debug('found start and converting to local time', startDate);
          const officeDate = Office.context.mailbox.convertToLocalClientTime(startDate);
          Logger.debug('office date', officeDate);
          const eventDate = new Date(
            officeDate.year,
            officeDate.month,
            officeDate.date,
            officeDate.hours,
            officeDate.minutes,
            officeDate.seconds,
          );
          Logger.debug('event date', eventDate.toISOString());
          this.setState({ itemStart: eventDate, isGettingItemStart: false });
        } else {
          //retrieve the start async
          Logger.debug('getting start async');
          appointment.start.getAsync((result: Office.AsyncResult<Date>) => {
            if (result.status !== Office.AsyncResultStatus.Succeeded) {
              console.error(result.error.message);
              this.setErrorMessage(result.error.message);
              this.setState({ itemStart: undefined, isGettingItemStart: false });
            } else {
              const asyncResult = result.value;
              Logger.debug('async result', asyncResult);
              const officeDate = Office.context.mailbox.convertToLocalClientTime(asyncResult);
              Logger.debug('office date', officeDate);
              const eventDate = new Date(
                officeDate.year,
                officeDate.month,
                officeDate.date,
                officeDate.hours,
                officeDate.minutes,
                officeDate.seconds,
              );
              Logger.debug('event date', eventDate.toISOString());
              this.setState({ itemStart: eventDate, isGettingItemStart: false });
            }
          });
        }
      } else {
        Logger.debug('item is undefined, setting start to undefined');
        this.setState({ itemStart: undefined, isGettingItemStart: false });
      }
    } catch (err) {
      Logger.debug('get start error', err);
      this.setErrorMessage(err);
    } finally {
      this.setState({ isGettingItemStart: false });
    }
  };

  loadTaskForAppointment = async () => {
    try {
      if (!this.state.itemId || !this.state.itemStart) {
        return;
      }

      this.setState({ isAppLoading: true });
      Logger.debug('load task started');

      //get the task for the event in Outlook.
      //this can be a task in an organizational unit
      //we can be logged into another organizational unit because Outlook doesn't make this difference
      const accessToken = await this.getAccessToken(apiRequest.scopes);
      const tasks = await apiGetTaskForEvent(
        this.state.itemId,
        this.state.itemStart,
        accessToken,
        this.state.globalDataCache,
      );

      if (tasks && tasks.tasks && tasks.tasks.length > 0) {
        const task = tasks.tasks[0];
        Logger.debug('taskId', task.taskId);
        this.setState({ task: task });
        //when the API signals that this task is of an organizational unit, we should set globally so we can update the task later
        if (tasks.orgUnitId) {
          this.setOrgUnit(tasks.orgUnitId);
        } else {
          this.setOrgUnit(undefined);
        }
      } else {
        Logger.debug('No task found');
        this.setState({ task: undefined });
      }
    } catch (err) {
      Logger.debug('load data error', err);
      this.setState({ task: undefined });
      this.setErrorMessage(err);
    } finally {
      this.setState({ isAppLoading: false });
    }
  };

  //
  // Mobile functions
  //
  getWindowWidth = (): number => {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  };

  getWindowHeight = (): number => {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  };

  onResize = () => {
    const width = this.getWindowWidth();
    const height = this.getWindowHeight();
    this.setState({
      windowSize: width,
      windowHeight: height,
      isMobileView: width >= globalDesktopSize ? false : true,
    });
  };

  render() {
    return <AppContext.Provider value={this.state}>{this.props.children}</AppContext.Provider>;
  }
}

export default AppContextProvider;
