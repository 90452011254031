import * as React from 'react';
import CurrentUser from 'models/currentuser';
import AppError from 'utils/appError';
import Task from 'models/tasks/task';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { globalDesktopHeight, globalDesktopSize } from 'globalConstants';
import { IGraphInterface } from './AppContextProvider';
import OutlookService from 'services/Office/outlookService';

export interface IAppContext {
  useDarkMode: boolean;
  setUseDarkMode: (useDarkMode: boolean) => void;
  isAppLoading: boolean;
  isContentLoading: boolean;
  showContentLoader: () => void;
  hideContentLoader: () => void;
  error?: AppError;
  showNotification: (msg: string, isError?: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setError: (error: string | any) => void;
  getAccessToken: (scopes: string[]) => Promise<string>;
  hasScopes: (scopes: string[]) => Promise<boolean>;
  isAuthenticated: boolean;
  isAuthInProgress: boolean;
  user: CurrentUser;
  task: Task | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
  itemId: string | undefined;
  itemStart: Date | undefined;
  isGettingItemId: boolean;
  isGettingItemStart: boolean;
  isNewAppointment: boolean;
  isReady: () => boolean;
  globalDataCache: GlobalDataCache;
  firstRun: boolean;
  cacheMiss: (count: number) => void;
  getGraphInterface: (scopes: string[], tenantId?: string) => Promise<IGraphInterface>;
  isMobileView: boolean;
  windowSize: number;
  windowHeight: number;
  hasResourcePanel: boolean;
  showResourcePanel: boolean;
  isMainNavCollapsed: boolean;
  startFirstRun: () => void;
  login: () => void;
  logout: () => void;
  globalFilter: string[];
  setGlobalFilter: (filter: string[]) => void
}

const appContext: IAppContext = {
  useDarkMode: false,
  setUseDarkMode: () => {},
  isAppLoading: false,
  isContentLoading: false,
  showContentLoader: () => {},
  hideContentLoader: () => {},
  error: undefined,
  showNotification: () => {},
  setError: () => {},
  getAccessToken: async () => '',
  hasScopes: async () => false,
  isAuthenticated: false,
  isAuthInProgress: false,
  user: CurrentUser.getEmptyUser(),
  task: undefined,
  item: undefined,
  itemId: undefined,
  itemStart: undefined,
  isGettingItemId: false,
  isGettingItemStart: false,
  isNewAppointment: false,
  isReady: () => false,
  globalDataCache: new GlobalDataCache(),
  firstRun: false,
  startFirstRun: () => {},
  cacheMiss: () => {},
  getGraphInterface: async () => {
    return { accessToken: '', client: OutlookService.getGraphClient('') };
  },
  windowSize: globalDesktopSize,
  isMobileView: false,
  windowHeight: globalDesktopHeight,
  hasResourcePanel: false,
  showResourcePanel: false,
  isMainNavCollapsed: false,
  login: () => {},
  logout: () => {},
  globalFilter: [],
  setGlobalFilter: () => {},
};

const AppContext = React.createContext(appContext);
AppContext.displayName = 'Global app context';

export default AppContext;
