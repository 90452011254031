import { mapFromTasksArray } from 'mappings/taskMapping';
import OutlookTasksDTO from 'models/dto/outlook/outlookTasks';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import OutlookTasks from 'models/outlook/outlookTasks';

export function mapFromOutlookTasks(tasksDTO: OutlookTasksDTO, globalDataCache: GlobalDataCache): OutlookTasks {
  const taskCol = new OutlookTasks();

  taskCol.tasks = mapFromTasksArray(tasksDTO.tasks, globalDataCache);
  taskCol.tasks.forEach((task) => (task.taskStates = globalDataCache.taskStates.items));
  taskCol.orgUnitId = tasksDTO.orgUnitId;

  return taskCol;
}
